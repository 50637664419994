<template>
  <div class="flex flex-col">
    <div>
      <div class="flex-1 flex gap-3 items-center">
        <fw-tag
          size="lg"
          class="px-2"
          :type="
            userMap.status == 'outdated'
              ? 'light'
              : userMap.status == 'approved'
              ? 'primary'
              : userMap.status == 'pre_approved'
              ? 'light-primary'
              : userMap.status == 'submitted'
              ? 'light-primary'
              : userMap.status == 'declined'
              ? 'light-danger'
              : userMap.status == 'draft'
              ? 'light-orange'
              : 'medium'
          "
          >v.{{ userMap.version || 'next' }}</fw-tag
        >
        <fw-heading muted size="h3">Versão do mapa de férias</fw-heading>
      </div>
    </div>

    <div class="flex-1 flex flex-col gap-5 my-5">
      <div>
        <ContentLoader v-if="loading" type="row"></ContentLoader>
        <div v-else-if="map?.key && userMap?.key">
          <BlockYearActivity
            v-if="isDraft || isApproved"
            class="mt-1 mb-3"
            :data="yearActivity.data"
            :custom-class="yearActivity.colors"
            :custom-icons="yearActivity.icons"
            :year="map.year"
            :christmas-tree="false"
            :max-value="1"
            expandable
          />

          <PanelMapUserDaysList
            :user-map="userMap"
            :days="mapUserDays"
            :can-add-partial-day="canAddPartialDay"
            :changed-days="changedDays"
            :selected-days="selectedDays"
            :year="map.year"
            :validations="validations"
            :saving-data="savingData"
            :days-count="currentVersionMotiveDaysCount"
            @open-modal-add-days="$emit('open-modal-add-days', $event)"
            @update-day="$emit('update-day', $event)"
            @delete-day="$emit('delete-day', $event)"
            @accumulate-day="$emit('accumulate-day', $event)"
            @accumulate-remaining="$emit('accumulate-remaining', $event)"
          />
        </div>
      </div>

      <fw-panel v-if="canShowMessagesPanel" title="Atividade">
        <b-tabs v-model="activeTab" :animated="false" @input="getTabData">
          <b-tab-item :label="$t('comments')" value="comments">
            <PanelMessages
              :users="users"
              :messages="comments"
              :pagination="pagination.comments"
              :loading="loading || loadingComments"
              :show-public-private-toggle="false"
              :force-hide-private-messages="false"
              :can-add-message="validations?.can_add_message"
              :default-add-message-is-private="false"
              :ignore-confirm-public-message="true"
              :allow-copy-files="false"
              from-decision-label="Decisão"
              @page-changed="commentsPageChanged"
              @add-message="addComment"
              @delete-message="deleteComment"
            />
          </b-tab-item>
        </b-tabs>
      </fw-panel>
    </div>

    <fw-panel-info debug label="Map data (raw)">
      <json-viewer
        :value="{ map, userMap, mapUserDays, validations, comments, activityLogs, yearActivity, canAddPartialDay }"
      ></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import {
  MAP_USER_STATUS,
  MAP_USER_STATUS_COLORS,
  FULL_DAY_TYPES,
  PARTIAL_DAY_TYPES,
  MAP_USER_VERSION_REASONS,
  VACATIONS_DAY_TYPES,
  CREDIT_DAY_TYPES
} from '@/utils/index.js'
import PanelMessages from '@/fw-modules/fw-core-vue/ui/components/panels/PanelMessages'
import BlockYearActivity from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockYearActivity'
import PanelMapUserDaysList from '@/components/panels/PanelMapUserDaysList'
// import difference from 'lodash/difference'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
// import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'

export default {
  components: {
    PanelMessages,
    PanelMapUserDaysList,
    ContentLoader,
    BlockYearActivity
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    savingData: {
      type: Boolean,
      default: false
    },
    map: {
      type: Object,
      default: () => {}
    },
    userMap: {
      type: Object,
      default: () => {}
    },
    mapUserDays: {
      type: Array,
      default: () => []
    },
    yearActivity: {
      type: Object,
      default: () => {}
    },
    validations: {
      type: Object,
      default: () => {}
    },
    canAddPartialDay: {
      type: Boolean,
      default: false
    },
    currentVersionMotiveDaysCount: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      colors: Object.freeze(MAP_USER_STATUS_COLORS),
      statusLabels: Object.freeze(MAP_USER_STATUS),
      vacationDayTypes: Object.freeze(VACATIONS_DAY_TYPES),
      creditDayTypes: Object.freeze(CREDIT_DAY_TYPES),
      fullDayTypes: Object.freeze(FULL_DAY_TYPES),
      partialDayTypes: Object.freeze(PARTIAL_DAY_TYPES),
      versionsMotives: Object.freeze(MAP_USER_VERSION_REASONS),

      // loading: false,
      loadingComments: false,
      loadingActivity: false,

      activeTab: 'comments',

      comments: [],
      activityLogs: [],
      users: {},

      pagination: {
        comments: {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 50
        },
        activity: {
          page: 1,
          totalResults: 0,
          totalPages: 1,
          limit: 50
        }
      }
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language
    },

    changedDays() {
      let days = {}

      for (const day of this.mapUserDays) {
        if (day.is_from_previous_map == false) {
          days[day.date] = day
        }
      }

      return days
    },

    selectedDays() {
      let days = {}

      // Get days from previous version that were not changed in this version
      for (let index = 0; index < this.mapUserDays.length; index++) {
        const day = this.mapUserDays[index]
        if (day.is_from_previous_map == true) {
          days[day.date] = day
        } else if (day.is_from_previous_map == false && day.date in days) {
          delete days[day.date]
        }
      }

      // Remove del/acc days
      for (const [date, day] of Object.entries(days)) {
        // What to show on other versions?
        if (day.sync_operation != 'add') {
          delete days[date]
        }
      }

      return days
    },

    isDraft() {
      return this.userMap?.status === 'draft'
    },

    isApproved() {
      return this.userMap?.status === 'approved'
    },

    canShowMessagesPanel() {
      return true
    }
  },

  watch: {
    'userMap.key'(newVal) {
      if (newVal) {
        this.getTabData(this.activeTab)
      }
    }
  },

  mounted() {
    this.users = { ...this.users, [this.user.key]: this.user }
    this.getTabData(this.activeTab)
  },

  methods: {
    async getComments() {
      this.loadingComments = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserMapComments(this.userMap.key, {
          page: this.pagination.comments.page,
          limit: this.pagination.comments.limit
        })
        console.log('getUserMapComments :>> ', response)
        this.comments = response.comments

        this.users = { ...this.users, ...response.users }

        this.pagination.comments = {
          limit: response.pagination.active_limit,
          page: response.pagination.current_page,
          totalResults: response.pagination.total_items,
          totalPages: response.pagination.total_pages
        }
        this.comments = response.comments
      })

      this.loadingComments = false
    },

    commentsPageChanged(page) {
      console.log('commentsPageChanged :>> ', page)
      this.pagination.comments.page = page
      this.getComments()
    },

    activityPageChanged(page) {
      console.log('activityLogs :>> ', page)
      this.pagination.activity.page = page
      this.getActivityLogs()
    },

    async addComment(messageData, type = null) {
      console.log('messageData :>> ', messageData)
      try {
        const response = await this.api.addUserMapComments(this.userMap.key, {
          ...messageData,
          type: type ?? 'comment'
        })
        console.log('addedMessage :>> ', response)
        this.comments.unshift(response.comment)
      } catch (error) {
        this.savingMessageError = true
        const errorKey = utils.errors(error).getKey()
        console.log('error :>> ', error)
        this.$buefy.dialog.alert({
          title: 'Ocorreu um erro',
          message: `Não foi possível guardar a mensagem: ${errorKey}`,
          type: 'is-danger',
          duration: 4000
        })
      }
    },

    async deleteComment(comment) {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteUserMapComments(this.userMap.key, comment.key)
        console.log('deleteUserMapComments :>> ', response)
        this.comments = this.comments.filter(el => el.key != comment.key)
      })
    },

    async getActivityLogs() {
      this.loadingActivity = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserMapActivityLogs(this.userMap.key, {
          page: this.pagination.comments.page,
          limit: this.pagination.comments.limit
        })
        console.log('getUserMapActivityLogs :>> ', response)
        this.users = { ...this.users, ...response.users }

        this.pagination.activity = {
          limit: response.pagination.active_limit,
          page: response.pagination.current_page,
          totalResults: response.pagination.total_items,
          totalPages: response.pagination.total_pages
        }
        this.activityLogs = response.logs
      })

      this.loadingActivity = false
    },

    getTabData(tab) {
      if (tab == 'comments') this.getComments()
      else if (tab == 'activity') this.getActivityLogs()
    }
  }
}
</script>

<i18n>
{
  "en": {
    "applyFilters": "Apply filters",
    "activity": "Atividade",
    "comments": "Mensagens",
    "syncOperations": {
      "add": "Adicionado",
      "del": "Removido",
      "acc": "Cumulado"
    },
    "types": {
      "morning": "manhã",
      "afternoon": "tarde",
      "halfday": "",
      "creditday-morning": "manhã",
      "creditday-afternoon": "tarde",
      "creditday-halfday": ""
    }
  },
  "pt": {
    "applyFilters": "Aplicar filtros",
    "activity": "Atividade",
    "comments": "Mensagens",
    "syncOperations": {
      "add": "Adicionado",
      "del": "Removido",
      "acc": "Cumulado"
    },
    "types": {
      "morning": "manhã",
      "afternoon": "tarde",
      "halfday": "",
      "creditday-morning": "manhã",
      "creditday-afternoon": "tarde",
      "creditday-halfday": ""
    }
  }
}
</i18n>

<style>
.b-checkbox {
  border-radius: inherit !important;
}
</style>
