<template>
  <div>
    <fw-panel
      v-if="!isStarterMap && !isLatestUserMap"
      :title="isDraft ? 'Alterações' : 'Resumo de alterações'"
      :counter="changedDaysCount"
      :custom-title-class="isDraft ? 'text-yellow-600' : ''"
      :muted="!isDraft"
      class="my-5"
    >
      <template v-if="editMode && !isStarterMap && isDraft" #toolbar>
        <fw-button
          v-if="validations?.can_add_days && !isAccumulateDaysVersion"
          type="link"
          @click.native="$emit('open-modal-add-days')"
          >Adicionar dias</fw-button
        >
        <fw-button
          v-else-if="validations?.can_edit_days && isAccumulateDaysVersion && daysCount.remaining > 0"
          type="link"
          @click.native="$emit('accumulate-remaining')"
          >Cumular restantes dias</fw-button
        >
      </template>

      <fw-panel-info v-if="isObjectEmpty(changedDays)" empty class="mb-4">
        Ainda não existem alterações registadas nesta versão.
      </fw-panel-info>
      <div v-else>
        <div v-if="isObjectEmpty(changedDays) && isDraft" class="mb-5 text-gray-500 text-sm">
          A lista seguinte identifica as alterações que pretende submeter para aprovação, relativamente ao seu mapa de
          férias, de acordo com a última versão aprovada do mesmo. Se pretender cancelar a alteração a um ou mais dias,
          carregar em <span class="font-semibold">Cancelar</span>.
        </div>
        <BlockDaysByMonth :days-grouped-by-month="changedDaysGroupedByMonth">
          <!-- Changes -->
          <template #days="{ days }">
            <RecordMapUserDay
              v-for="(day, dayIndex) in days"
              :key="dayIndex"
              :day="day"
              :year="year"
              :is-creditdays="isCreditdays"
              :remaining-days="daysCount.remaining"
              :can-add-partial-day="canAddPartialDay"
              :manager-mode="false"
              :saving-data="savingData"
              :edit-mode="validations?.can_edit_days && isDraft"
              :type="isCanceled ? 'canceled' : 'change'"
              @update="$emit('update-day', $event)"
              @delete="$emit('delete-day', $event)"
            />
          </template>
        </BlockDaysByMonth>
      </div>
    </fw-panel>

    <fw-panel
      v-if="!isLatestUserMap && (isStarterMap || isDraft)"
      :title="isStarterMap && isDraft ? 'Dias a propor' : 'Dias marcados'"
      :counter="isStarterMap ? changedDaysCount : selectedDaysCount"
    >
      <template v-if="validations?.can_add_days && days.length && isStarterMap" #toolbar>
        <fw-button type="link" :disabled="!editMode" @click.native="$emit('open-modal-add-days')"
          >Adicionar dias</fw-button
        >
      </template>

      <template #default>
        <div
          v-if="!days.length && isStarterMap && isDraft && validations?.can_add_days"
          class="flex flex-col gap-3 items-center bg-gray-100 rounded-xl p-5 mb-5"
        >
          <div class="relative w-28 flex items-center justify-center">
            <fw-icon-calendar class="h-14 w-14 mt-3 text-gray-300" />
            <fw-icon-sun class="h-12 w-12 text-primary absolute right-0 top-0" />
          </div>
          <div class="flex flex-col gap-1 items-center justify-center">
            <div class="text-base font-medium text-gray-500">
              Já pode marcar as suas férias para {{ year }}, o seu mapa já está aberto.
            </div>
            <div class="text-sm text-gray-500">Para começar, adicione alguns dias de férias ao mapa.</div>
          </div>
          <div>
            <fw-button type="link" @click.native="$emit('open-modal-add-days')">Adicionar dias</fw-button>
          </div>
        </div>
        <fw-panel-info v-else-if="!selectedDaysCount && !isStarterMap" empty class="mb-4">
          Não existem dias registados.
        </fw-panel-info>

        <BlockDaysByMonth
          v-if="isStarterMap"
          :days-grouped-by-month="changedDaysGroupedByMonth"
          :collapsed="!isDraft"
          :show-days-on-collapsed="!isDraft"
        >
          <!-- Starter map -->
          <template #days="{ days }">
            <RecordMapUserDay
              v-for="(day, dayIndex) in days"
              :key="dayIndex"
              :day="day"
              :year="year"
              :saving-data="savingData"
              :remaining-days="daysCount.remaining"
              :can-add-partial-day="canAddPartialDay"
              :is-creditdays="isCreditdays"
              :edit-mode="
                editMode &&
                  ((creditDayTypes.includes(day.type) && isCreditdays) ||
                    (vacationDayTypes.includes(day.type) && !isCreditdays))
              "
              :as-changelog="isDraft"
              @update="$emit('update-day', $event)"
              @delete="$emit('delete-day', $event)"
            />
          </template>
        </BlockDaysByMonth>

        <BlockDaysByMonth v-else-if="isDraft" :days-grouped-by-month="selectedDaysGroupedByMonth">
          <!-- All days -->
          <template #days="{ days }">
            <RecordMapUserDay
              v-for="(day, dayIndex) in days"
              :key="dayIndex"
              :day="day"
              :year="year"
              :simple-view="true"
              :remaining-days="daysCount.remaining"
              :can-add-partial-day="canAddPartialDay"
              :saving-data="savingData"
              :edit-mode="
                editMode &&
                  !isAccumulateDaysVersion &&
                  day.sync_operation != 'acc' &&
                  ((creditDayTypes.includes(day.type) && isCreditdays) ||
                    (vacationDayTypes.includes(day.type) && !isCreditdays))
              "
              :is-creditdays="isCreditdays"
              :accumulate-mode="canAccumulateSelected"
              @update="$emit('update-day', $event)"
              @delete="$emit('delete-day', $event)"
              @accumulate="$emit('accumulate-day', $event)"
            />
          </template>
        </BlockDaysByMonth>
      </template>
    </fw-panel>

    <fw-panel v-if="userMap?.birthday && isLatestUserMap" :title="'Dia de aniversário'" :counter="1" class="my-5">
      <template #default>
        <BlockDaysByMonth
          v-if="userMap?.birthday"
          :days-grouped-by-month="birthdayMonth"
          collapsed
          show-days-on-collapsed
        >
          <!-- All days -->
          <template #days="{ days }">
            <RecordMapUserDay
              v-for="(day, dayIndex) in days"
              :key="dayIndex"
              :day="day"
              :year="year"
              :simple-view="true"
              :remaining-days="0"
              :manager-mode="false"
              :edit-mode="false"
              :is-creditdays="false"
              :accumulate-mode="false"
            />
          </template>
        </BlockDaysByMonth>
      </template>
    </fw-panel>

    <fw-panel v-if="isLatestUserMap" :title="'Dias efetivos'" :counter="selectedDaysCount" class="my-5">
      <template #default>
        <BlockDaysByMonth :days-grouped-by-month="selectedDaysGroupedByMonth" collapsed show-days-on-collapsed>
          <!-- All days -->
          <template #days="{ days }">
            <RecordMapUserDay
              v-for="(day, dayIndex) in days"
              :key="dayIndex"
              :day="day"
              :year="year"
              :simple-view="true"
              :remaining-days="0"
              :manager-mode="false"
              :edit-mode="false"
              type="latest"
              :is-creditdays="isCreditdays"
              :accumulate-mode="false"
            />
          </template>
        </BlockDaysByMonth>
      </template>
    </fw-panel>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer
        :value="{
          days,
          selectedDays,
          changedDays,
          validations,
          isCreditdays,
          year,
          ...daysCount,
          canAddPartialDay
        }"
      ></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import RecordMapUserDay from '@/components/records/RecordMapUserDay'
import BlockDaysByMonth from '@/components/blocks/BlockDaysByMonth'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import { CREDIT_DAY_TYPES, VACATIONS_DAY_TYPES, countDays } from '@/utils/index.js'

export default {
  components: {
    RecordMapUserDay,
    BlockDaysByMonth
  },
  props: {
    days: {
      type: Array
    },
    userMap: {
      type: Object
    },
    year: {
      type: Number
    },
    validations: {
      type: Object
    },
    changedDays: {
      type: Object
    },
    selectedDays: {
      type: Object
    },
    canAddPartialDay: {
      type: Boolean,
      default: false
    },
    daysCount: {
      type: Object,
      default: () => {
        return {
          total: 22,
          selected: 0,
          remaining: 22
        }
      }
    },
    isLatestUserMap: {
      type: Boolean,
      default: false
    },
    simpleView: {
      type: Boolean,
      default: false
    },
    savingData: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      vacationDayTypes: Object.freeze(VACATIONS_DAY_TYPES),
      creditDayTypes: Object.freeze(CREDIT_DAY_TYPES)
    }
  },

  computed: {
    editMode() {
      return this.userMap.blocked === false && this.isDraft && this.validations?.can_edit_days
    },

    isStarterMap() {
      return this.userMap?.version_motive === 'starter_map'
    },

    isCreditdays() {
      return this.userMap?.version_motive == 'credit_days'
    },

    isVacationsVersion() {
      return this.userMap?.version_motive == 'vacations'
    },

    isAccumulateDaysVersion() {
      return this.userMap?.version_motive == 'accumulate_days'
    },

    canAccumulateSelected() {
      return this.isAccumulateDaysVersion && this.validations?.can_edit_days
    },

    isDraft() {
      return this.userMap?.status === 'draft'
    },

    isCanceled() {
      return this.userMap?.status === 'canceled'
    },

    changedDaysGroupedByMonth() {
      return groupBy(Object.values(this.changedDays), x => Dates.buildCore(x.date).format('YYYY-MM'))
    },

    birthdayMonth() {
      if (!this.userMap?.birthday) return null
      const month = Dates.buildCore(this.userMap?.birthday).format('YYYY-MM')

      return {
        [month]: [
          {
            key: 'birthday',
            type: 'birthday',
            sync_operation: 'add',
            date: this.userMap?.birthday
          }
        ]
      }
    },

    selectedDaysGroupedByMonth() {
      return groupBy(
        Object.values(this.selectedDays).toSorted((a, b) => new Date(a.date) - new Date(b.date)),
        x => Dates.buildCore(x.date).format('YYYY-MM')
      )
    },

    selectedDaysCount() {
      if (this.isObjectEmpty(this.selectedDays)) return 0
      return countDays(Object.values(this.selectedDays))
    },

    changedDaysCount() {
      if (this.isObjectEmpty(this.changedDays)) return 0
      return countDays(Object.values(this.changedDays))
    }
  },

  methods: {
    isObjectEmpty(obj) {
      return utils.isObjectEmpty(obj)
    }
  }
}
</script>
